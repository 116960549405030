import React, { useState } from 'react';
import Ball from './Ball';
import ModalApp from './ModalApp';
import './Button.css';


function Button(){
    const [questionIndex, setQuestionIndex] = useState(1);
    const [topIndex, setTopIndex] = useState(-125);


    const goBack = () =>
      setQuestionIndex((prevQuestionIndex) => prevQuestionIndex - 0.5);
    const goToNext = () =>
      setQuestionIndex((prevQuestionIndex) => prevQuestionIndex + 0.5);
  
    const onFirstQuestion = questionIndex === 0.5;
    const onLastQuestion = questionIndex === 3;

    const goBack2 = () =>
    setTopIndex((topQuestionIndex) => topQuestionIndex - 25);
  const goToNext2 = () =>
  setTopIndex((topQuestionIndex) => topQuestionIndex + 25);

  const topFirstQuestion = topIndex === 0;
  const topLastQuestion = topIndex === -250;

    const divStyle = {
        top: topIndex + 'px',
        animation: 'move ' + questionIndex +'s linear alternate infinite' ,
        
        
      };


return(
     <div>
          <div className ="movepics" style={divStyle} >
    < Ball/>
  </div>  
 
  <input className="open" id="top-box" type="checkbox" hidden />
<label className="btn" for="top-box"/>
<div className="top-panel">
   <div className="message">
   <div className="modalMenu">
    <div className="">
        <button className="button8" onClick={goBack} disabled={onFirstQuestion}>  &#x2606;</button>
    </div>
    <div className="">
        <button className="button8" onClick={goToNext} disabled={onLastQuestion}>&#x262F;</button>
    </div>
    <div className="">
        <button className="button8" onClick={goToNext2} disabled={topFirstQuestion}>&#9929;</button>
    </div>
    <div className="">
        <button className="button8" onClick={goBack2} disabled={topLastQuestion}>&#9964;</button>
    </div>
    <ModalApp/>
  </div>
 
  
   </div>
</div>
    
</div>
    
)
}

export default Button;