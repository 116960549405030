import React from 'react';
import './App.css';
import Button from './Button';


function App() {
  return (
    <div className="App">
    <div className ="wrapper">
     <Button/>
     
  </div>

  </div>
  );
}

export default App;
